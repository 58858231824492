.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 6px;
  margin: 8px;
  border-radius: 3px;
  border: 1px solid #c2ddff;
  color: #2b2eff;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #c2ddff;
}

.paginationActive a {
  color: #2b2eff;
  background-color: #c2ddff;
}

.paginationDisabled a {
  color: rgba(128, 128, 128, 0.397);
  background-color: rgba(128, 128, 128, 0.425);
}